<template>
    <div id="adobe-dc-view" />
</template>

<script>
export default {
    name: 'pdf-view',
    props: {
        url: String,
        embedMode: {
            type: String,
            default: 'IN_LINE',
        },
    },
    mounted() {
        this.loadAdobeScript().then(() => {
            // eslint-disable-next-line no-undef
            const adobeDCView = new AdobeDC.View({
                clientId: process.env.VUE_APP_ADOBE_CLIENT_ID,
                divId: 'adobe-dc-view',
                locale: 'zh-TW',
            });

            adobeDCView.previewFile({
                content: { location: { url: this.url } },
                metaData: { fileName: 'content.pdf' },
            }, {
                embedMode: this.embedMode,
                showPrintPDF: false,
                showDownloadPDF: false,
            });
        }).catch((error) => {
            console.error('Adobe SDK 加載失敗:', error);
        });
    },
    methods: {
        loadAdobeScript() {
            return new Promise((resolve) => {
                if (window.AdobeDC) {
                    resolve();
                } else {
                    const adobeScript = document.createElement('script');
                    // adobeScript.setAttribute('src', 'https://documentcloud.adobe.com/view-sdk/main.js');
                    adobeScript.setAttribute('src', 'https://acrobatservices.adobe.com/view-sdk/viewer.js');
                    document.head.appendChild(adobeScript);
                    document.addEventListener('adobe_dc_view_sdk.ready', resolve);
                }
            });
        },
    },
};
</script>

<style>
#adobe-dc-view {}
</style>
