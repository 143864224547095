import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './inits';

Vue.config.productionTip = false;

Sentry.init({
    Vue,
    dsn: 'https://d50bb23948808dcfb4848e44f049d512@o4508063320637440.ingest.us.sentry.io/4508063322210304',
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    // /^https:\/\/2947\.api\.gosu\.bar\//, /^https:\/\/directus\.gosu\.bar\//
    tracePropagationTargets: [],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
